import Vue from 'vue'
import store from '@/store'
import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_VALIJA)

export default {
  obtener_configuracion(options) {
    return http.get(utils.url_options(`/configuracion`,options));
  }

  ,crear_configuracion(payload) {
    return http.post(`/configuracion`, payload);
  }

  ,editar_configuracion(id, payload) {
    return http.put(`/configuracion/${id}`, payload);
  }

  ,eliminar_configuracion(id) {
    return http.delete(`/configuracion/${id}`);
  }
};