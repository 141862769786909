<template>
  <div>
    <h1 class="col-sm-12 text-right mt-4 mb-4">Configuración</h1>

    <DataGrid :config="dataGridConfig" :data="configuraciones" :select="seleccionar" @actions="dataGridActions">
      <div class="row">
        <div class="col-sm-12">
          <button class="btn btn-warning mr-2" @click="obtener_configuracion">Recargar</button>
          <button v-if="$auth.can('valija','configuracion_crear')" class="btn btn-success mr-2" @click="modal_detalles=true">Crear configuración</button>
          <button v-if="$auth.can('valija','configuracion_editar')" class="btn btn-primary mr-2" @click="editar_configuracion">Editar configuración</button>
          <button v-if="$auth.can('valija','configuracion_eliminar')" class="btn btn-danger mr-2" @click="eliminar_configuracion">Eliminar configuración</button>
        </div>
      </div>
    </DataGrid>

    <Detalle v-if="modal_detalles" :configuracion="configuracion" @close="cerrar_modal_detalles" @update="obtener_configuracion" />
  </div>
</template>

<script type="text/javascript">
  import DataGrid from '@/components/DataGridV2'
  import Detalle from './Configuracion/Detalle'

  import api from './../api/configuracion'

  export default {
    components: {
      DataGrid, Detalle
    }
    ,data: function() {
      return {
        dataGridConfig: {
          name: 'configuracion'
          ,cols: {
            nombre: 'Nombre'
            ,codigo: 'Código'
            ,descripcion: 'Descripción'
            ,created_at: 'Creación'
          }
          ,paginator: {
            pagina_actual: 1
            ,total_registros: 1
            ,registros_por_pagina: 20
          }
          ,mutators: {
            
          }
        }
        ,options: {
          page: 1
          ,order_col: 'id'
          ,order_dir: 'desc'
          ,limit: 20
          ,filters: []
        }
        ,seleccionar: false
        ,seleccionadas: []
        ,configuraciones: []
        ,configuracion: null
        ,modal_detalles: false
      }
    }
    ,methods: {
      dataGridActions: function(tipo, data) {
        if (tipo == 'options') {
          this.options = data;
          this.obtener_configuracion();
        }else {
          this.seleccionadas = data;
          this.seleccionar = true;
        }
      }
      ,obtener_configuracion: async function() {
        try {
          let res = (await api.obtener_configuracion(this.options)).data;

          this.configuraciones = res.data;
          this.dataGridConfig.paginator.pagina_actual = res.current_page;
          this.dataGridConfig.paginator.total_registros = res.total;
          this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.per_page);
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e, 'Error');
        }
      }
      ,cerrar_modal_detalles: function() {
        this.modal_detalles = false;
        this.seleccionar = false
        this.configuracion = null;
      }
      ,editar_configuracion: function() {
        if (this.seleccionadas.length == 0)
          return this.$helper.showMessage('Error','Debes seleccionar una configuración a editar','error','alert');

        this.configuracion = this.seleccionadas[0];
        this.modal_detalles = true;
      }
      ,eliminar_configuracion: async function() {
        try {
          if (this.seleccionadas.length == 0)
            return this.$helper.showMessage('Error','Debes seleccionar al menos una configuración a eliminar','error','alert');

          this.seleccionadas.forEach(async conf => {
            let res = (await api.eliminar_configuracion(conf.id)).data;
            this.$log.info('res',res);
          });

          this.seleccionar = false;
          this.obtener_configuracion();
        }catch(e) {
          this.$log.info('Error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>