<template>
  <div>
    <Modal @close="cerrar_modal">
      <form @submit.prevent="guardar_datos">
        <div class="title">{{ conf.id ? 'Editar configuración' : 'Nueva configuración' }}</div>
        <div class="body">
          <div class="row form-group">
            <label for="nombre" class="col-form-label col-sm-2">Nombre</label>
            <div class="col-sm-10"><input v-model="conf.nombre" type="text" name="nombre" id="nombre" class="form-control"></div>
          </div>
          <div class="row form-group">
            <label for="codigo" class="col-form-label col-sm-2">Código</label>
            <div class="col-sm-10"><input v-model="conf.codigo" type="text" name="codigo" id="codigo" class="form-control"></div>
          </div>
          <div class="row form-group">
            <label for="descripcion" class="col-form-label col-sm-2">Descripción</label>
            <div class="col-sm-10"><input v-model="conf.descripcion" type="text" name="descripcion" id="descripcion" class="form-control"></div>
          </div>
          <div class="row form-group">
            <label for="valor" class="col-form-label col-sm-2">Valor</label>
            <div class="col-sm-10"><input v-model="conf.valor" type="text" name="valor" id="valor" class="form-control"></div>
          </div>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <button class="btn btn-primary mr-2">Guardar configuración</button>
              <button type="button" class="btn btn-danger" @click="cerrar_modal">Cancelar</button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  import api from './../../api/configuracion'

  export default {
    components: {
      Modal
    }
    ,props: {
      configuracion: {
        type: Object
        ,default: function() {
          return {
            id: null
            ,nombre: null
            ,descripcion: null
            ,valor: null
          }
        }
      }
    }
    ,data: function() {
      return {
        conf: {
          nombre: null
          ,codigo: null
          ,descripcion: null
          ,valor: null
        }
      }
    }
    ,mounted: function() {
      if (this.configuracion && this.configuracion.id)
        this.conf = this.configuracion;
    }
    ,methods: {
      cerrar_modal: function() {
        this.$emit('close');
      }
      ,guardar_datos: async function() {
        try {
          let res = null;
          if (this.conf.id)
            res = (await api.editar_configuracion(this.conf.id, this.conf)).data;
          else
            res = (await api.crear_configuracion(this.conf)).data;

          this.$log.info('res', res);
          this.$emit('update');
          this.cerrar_modal();
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>